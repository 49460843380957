@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Source+Sans+3:wght@200;400;700&display=swap');

html,
body,
#root,
.app,
.content {
    width: 100%;
    font-family: "Source Sans 3", sans-serif;
    margin: 0;
    padding: 0;
}

.app {
    display: flex;
    position: relative;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.fc-popover {
    color: #222;
}

.fc-event:hover {
    cursor: pointer;
}

.color-legend *, .calendar-table * {
    font-size: 14px !important;
}

.pro-sidebar-inner {
    min-height: 100vh;
}